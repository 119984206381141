
import { frame1_icon, frame2_icon, frame3_icon, frame4_icon } from "./images";



export const REACT_APP_STRIPE_PUBLISHABLE_KEY =
  "pk_test_TYooMQauvdEDq54NiTphI7jx";
export const REACT_APP_STRIPE_SECRET_KEY = "sk_test_4eC39HqLyjWDarjtT1zdp7dc";

export const DropDownValues = [
  {
    title: "All",
    id: "all",
  },
  {
    title: "Scholarship H & A",
    id: "scholarship_honors_awards",
  },
  {
    title: "Leadership H & A",
    id: "leadership_honors_awards",
  },
  {
    title: "Citizenship H & A",
    id: "citizenship_honors_awards",
  },
  {
    title: "Sportsmanship H & A",
    id: "sportsmanship_honors_awards",
  },
  {
    title: "Scholarship R & A",
    id: "scholarship_roles_activites",
  },
  {
    title: "Leadership R & A",
    id: "leadership_roles_activites",
  },
  {
    title: "Citizenship R & A",
    id: "citizenship_roles_activites",
  },
  {
    title: "Sportsmanship R & A",
    id: "sportsmanship_roles_activites",
  },
];
export const honors_awards_dropdown = [
  {
    title: "All",
    id: "all",
  },
  {
    title: "Scholarship H & A",
    id: "scholarship_honors_awards",
  },
  {
    title: "Leadership H & A",
    id: "leadership_honors_awards",
  },
  {
    title: "Citizenship H & A",
    id: "citizenship_honors_awards",
  },
  {
    title: "Sportsmanship H & A",
    id: "sportsmanship_honors_awards",
  },
];
export const roles_activites_dropdown = [
  {
    title: "All",
    id: "all",
  },
  {
    title: "Scholarship R & A",
    id: "scholarship_roles_activites",
  },
  {
    title: "Leadership R & A",
    id: "leadership_roles_activites",
  },
  {
    title: "Citizenship R & A",
    id: "citizenship_roles_activites",
  },
  {
    title: "Sportsmanship R & A",
    id: "sportsmanship_roles_activites",
  },
];
export const SubDropDown = [
  {
    title: "Roles and Activites",
    id: "roles_activites",
  },
  {
    title: "Honors and Awards",
    id: "honors_awards",
  },
];
export const PieChartDropdown = [
  {
    title: "Year Started",
    id: "year_started",
  },
  {
    title: "Type of Schools",
    id: "types_of_school",
  },
];

export const maleSport = [
  {
    title: "Football",
    id: "football",
  },
  {
    title: "Basketball",
    id: "basketball",
  },
  {
    title: "Baseball",
    id: "baseball",
  },
  {
    title: "Soccer",
    id: "soccer",
  },
  {
    title: "Lacrosse",
    id: "lacrosse",
  },
  {
    title: "Track and Field",
    id: "track_and_field",
  },
  {
    title: "Wrestling",
    id: "wrestling",
  },
  {
    title: "Cross Country",
    id: "cross_country",
  },
  {
    title: "Tennis",
    id: "tennis",
  },
  {
    title: "Golf",
    id: "golf",
  },
  {
    title: "Swimming",
    id: "swimming",
  },
];

export const femaleSports = [
  {
    title: "Basketball",
    id: "basketball",
  },
  {
    title: "Volleyball",
    id: "volleyball",
  },
  {
    title: "Soccer",
    id: "soccer",
  },
  {
    title: "Field Hockey",
    id: "field_hockey",
  },
  {
    title: "Softball",
    id: "softball",
  },
  {
    title: "Track and Field",
    id: "track_and_field",
  },
  {
    title: "Tennis",
    id: "tennis",
  },
  {
    title: "Golf",
    id: "golf",
  },
  {
    title: "Swimming",
    id: "swimming",
  },
];
export const participationLevel = [
  {
    title: "Junior Varsity",
    id: "Junior Varsity",
  },
  {
    title: "Varsity",
    id: "Varsity",
  },
  {
    title: "Club Level",
    id: "Club Level",
  },
  {
    title: "Lettered",
    id: "Lettered",
  },
  {
    title: "Team Captain/Co-Captain",
    id: "Team Captain/Co-Captain",
  },
  {
    title: "Most Valuable Player",
    id: "Most Valuable Player",
  },
  {
    title: "All-District",
    id: "All-District",
  },
  {
    title: "All-Conference",
    id: "All-Conference",
  },
  {
    title: "All State",
    id: "All State",
  },
  {
    title: "All American",
    id: "All American",
  },
];

export const ScndStepList = [
  {
    title: "Noteable",
    id: "community",
  },
  {
    title: "Emerging",
    id: "somewhat_competitive",
  },
  {
    title: "Moderate",
    id: "competitive",
  },
  {
    title: "High",
    id: "most_competitive",
  },
];
export const universityDropDownList = [
  {
    title: "All",
    id: "all",
  },
  {
    title: "Noteable",
    id: "community",
  },
  {
    title: "Emerging",
    id: "somewhat_competitive",
  },
  {
    title: "Moderate",
    id: "competitive",
  },
  {
    title: "High",
    id: "most_competitive",
  },
];
export const universityCreateType = [
  {
    title: "Noteable",
    id: "community",
  },
  {
    title: "Emerging",
    id: "somewhat_competitive",
  },
  {
    title: "Moderate",
    id: "competitive",
  },
  {
    title: "High",
    id: "most_competitive",
  },
];
export const FirstStepList = [
  {
    title: "Freshman",
    id: "freshman",
  },
  {
    title: "Softmore",
    id: "sophomore",
  },
  {
    title: "Junior",
    id: "junior",
  },
  {
    title: "Senior",
    id: "senior",
  },
];

export const scholarshipDropdown = [
  {
    title: "Not Submit",
    id: "none",
  },
  {
    title: "Accepted",
    id: "accepted",
  },
  {
    title: "Rejected",
    id: "rejected",
  },
  {
    title: "Applied",
    id: "applied",
  },
];

export const dashboardDropdown = [
  {
    title: "Scholarship",
    id: "scholarship",
  },
  {
    title: "University",
    id: "university",
  },
];

export function convertObjectToString(obj) {
  // Extract values from the object
  let grades = obj.grades;
  let minGrade, maxGrade;

  if (grades && typeof grades === "string" && grades.includes(",")) {
    const gradesArray = grades
      .split(",")
      .map(Number)
      .sort((a, b) => a - b);
    minGrade = gradesArray[0];
    maxGrade = gradesArray[gradesArray.length - 1];
    grades = `${minGrade}th - ${maxGrade}th`;
  } else if (grades && typeof grades === "string") {
    minGrade = maxGrade = parseInt(grades, 10); // Convert to number
    grades = `${minGrade}th`;
  } else {
    // Handle case when grades is null, undefined, or not a string
    grades = "";
  }

  const weeks = obj.weeks;
  const hours = obj.hours;

  // Construct the string
  // const str =  `${grades}, ${weeks} Weeks / Year, ${hours} Hours / Week`;

  let str = "";

  // Add grades if available
  if (grades) {
    str += `${grades}`;
  }

  // Add weeks if available
  if (weeks) {
    if (str !== "") {
      // Add semicolon if previous part is added
      str += ", ";
    }
    str += `${weeks} Weeks / Year`;
  }

  // Add hours if available
  if (hours) {
    if (str !== "") {
      // Add semicolon if previous part is added
      str += ", ";
    }
    str += `${hours} Hours / Week`;
  }
  return str;
}

export function divideIntoGroups(maxNumber) {
  const groups = [
    {
      id: "empty",
      range: "Empty",
    },
  ];
  let start = 1;
  let end = 5;
  let id = 1; // Initialize the ID counter
  while (end <= maxNumber) {
    groups.push({ id: String(id), range: `${start}-${end}` });
    start += 5;
    end += 5;
    id += 5; // Increment the ID counter
  }
  // Add the remaining numbers if any
  if (start <= maxNumber) {
    groups.push({ id: String(id), range: `${start}-${maxNumber}` });
  } else if (maxNumber === 50) {
    // Add an additional group for numbers 51-52
    groups.push({ id: String(id), range: "51-52" });
  }
  return groups;
}

export const Dashboard_AR = [
  {
    title: "Scholarship Activities and Roles",
    id: "sc_roles",
  },
  {
    title: "Leadership Activities and Roles",
    id: "ld_roles",
  },
  {
    title: "Citizenship Activities and Roles",
    id: "ct_roles",
  },
  {
    title: "Sportsmanship Activities and Roles",
    id: "sp_roles",
  },
];
export const Dashboard_HA = [
  {
    title: "Scholarship Honors and Awards",
    id: "sc_honors",
  },
  {
    title: "Leadership Honors and Awards",
    id: "ld_honors",
  },
  {
    title: "Citizenship Honors and Awards",
    id: "ct_roles",
  },
  {
    title: "Sportsmanship Honors and Awards",
    id: "sp_honors",
  },
];

export const dashboard = [
  {
    title: "Scholarship Activities and Roles",
    id: "sc_roles",
  },
  {
    title: "Leadership Activities and Roles",
    id: "ld_roles",
  },
  {
    title: "Citizenship Activities and Roles",
    id: "ct_roles",
  },
  {
    title: "Sportsmanship Activities and Roles",
    id: "sp_roles",
  },
  {
    title: "Scholarship Honors and Awards",
    id: "sc_honors",
  },
  {
    title: "Leadership Honors and Awards",
    id: "ld_honors",
  },
  {
    title: "Citizenship Honors and Awards",
    id: "ct_honors",
  },
  {
    title: "Sportsmanship Honors and Awards",
    id: "sp_honors",
  },
];

export const countValuesEqualToOrLessThanScore = (array, score) => {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i] <= score) {
      count++;
    }
  }
  return count;
};

export function scrollAdjust() {
  // setTimeout(() => {
  //   let heightOfSystem = window.innerHeight;
  //   var ele = document.getElementById('set_my_scroll_window').offsetHeight;
  //   console.log('++++++++', ele)
  //   if (heightOfSystem > 700) {
  //     ele = ele - 50;
  //     ele = ele + 'px';
  //   }
  //   if (heightOfSystem < 700 && heightOfSystem < 650) {
  //     ele = ele - 200;
  //     ele = ele + 'px';
  //   }
  //   if (heightOfSystem < 700 && heightOfSystem > 650) {
  //     ele = ele - 130;
  //     ele = ele + 'px';
  //   }
  //   document.getElementById('set_my_scroll_window').style.height = ele;
  //   console.log(heightOfSystem + '--------------', ele)
  // }, 2000);
}

export function roundAndFormatNumberString(inputString) {
  // Check if the input string starts with zero
  if (inputString.startsWith("0")) {
    return "0";
  }

  // Check if the input string contains a decimal point
  if (inputString.includes(".")) {
    // Convert the input string to a number and round it
    const roundedNumber = Math.round(parseFloat(inputString));

    // Format the rounded number to display with a maximum of 2 decimal places
    const formattedNumber = roundedNumber.toFixed(2);

    return formattedNumber;
  } else {
    // If no decimal point exists, just round the number to the nearest integer
    const roundedNumber = Math.round(parseInt(inputString));
    return roundedNumber.toString(); // Convert back to string
  }
}

export const stringToInt = (value) => {
  if (typeof value === "string") {
    const parsed = parseInt(value, 10); // 10 is the radix parameter for decimal base
    if (!isNaN(parsed)) {
      return parsed;
    } else {
      return value; // Return the original value if it can't be converted
    }
  } else {
    return value; // Return the original value if it's not a string
  }
};

export const stripe_promise = () => {
  const { host } = window.location;

  var stripe_key =
    "pk_live_51NlPfvL5MmXwVUFIn1ARyU8TkD5JBpR7ORPXBQKkrTbLL4Mk1fuPz5wfWwl6cyLOLTBNzdzNaDqgffOPLHSv2i4W00yNMad4dF";

  if (host.includes("localhost") || host.includes("dev")) {
    stripe_key =
      "pk_test_51NlPfvL5MmXwVUFIogOFuwJfJ2ZEFLJIZdzHeEa1qTCQgTJUNvFpzcfwylB1jUzwWsZfzYmAFk6m9ffNf4X7fkUL00M9tCJcPr";
  } else {
    stripe_key =
      "pk_live_51NlPfvL5MmXwVUFIn1ARyU8TkD5JBpR7ORPXBQKkrTbLL4Mk1fuPz5wfWwl6cyLOLTBNzdzNaDqgffOPLHSv2i4W00yNMad4dF";
  }

  return stripe_key;
};

export const formatTestScores = (psatData = [], satData = []) => {
  return {
    psat: psatData.map((item) => ({
      math: item.math,
      reading_writing: item.reading,
      total: item.total,
      test_date: item.date,
    })),
    sat: satData.map((item) => ({
      math: item.math,
      reading_writing: item.reading,
      total: item.total,
      test_date: item.date,
    })),
  };
};

export const coverttestScores = (data) => {
  const formattedScores = [];

  data &&
    data.length > 0 &&
    data.forEach((item) => {
      formattedScores.push({
        math: item.math,
        reading: item.reading_writing,
        total: item.total,
        date: item.test_date,
        type: item.type,
      });
    });

  return formattedScores;
};

export const fixTitle = (title, number) => {
  if (title && !number) {
    if (title.length >= 10) {
      return `${title.slice(0, 8)}...`;
    } else {
      return title;
    }
  } else if (title && number) {
    if (title.length >= 20) {
      return `${title.slice(0, 18)}...`;
    } else {
      return title;
    }
  }
};

export const fixNews = (title) => {
  if (title) {
    if (title.length > 15) {
      return `${title.slice(0, 18)}...`;
    } else {
      return title;
    }
  }
};

export const getPlainText = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.innerText || tempDiv.textContent;
};

export const statusChange = (newStatus, questions, questionId) => {
  const updateScholarship = [...questions];

  const existingData = updateScholarship.find((item) => item.id === questionId);

  if (existingData) {
    // alert('')
    existingData.status = newStatus;
  }

  console.log(updateScholarship, "updateScholarshipupdateScholarship");
  return updateScholarship;
};

export const cardlisting = [
  { uperName: "Prompt Against", lowerName: "Your Data", img: frame4_icon },
  { uperName: "Complete College", lowerName: "Application", img: frame1_icon },
  { uperName: "Preview Resume", img: frame2_icon },
  { uperName: "Write Essay", img: frame3_icon },
];

export const styleComponent = { height: "calc(100dvh - 0px)" };


export const fixTooltip = (title) => {
  if (title) {
    if (title.length > 40) {
      return `${title.slice(0, 40)}....`;
    } else {
      return title;
    }
  }
};


export const getModalData = (newData, answers, questionId) => {
  const updatedData = [...answers];

  const existingDataIndex = updatedData.findIndex((item) => item.id === questionId);

  if (existingDataIndex !== -1) {

    updatedData[existingDataIndex] = {
      ...updatedData[existingDataIndex],
      activityName: newData.answer,
      weeksPerYear: newData.weeks,
      hoursPerWeek: newData.hours,
      gradesInvolved: newData.grades,
      id: newData.id,
      participationLevelId: newData.accomplishment ? newData.accomplishment : '',
      dropdownId: newData.sport ? newData.sport : '',
    };

  } else {

    updatedData.push({
      activityName: newData.answer,
      weeksPerYear: newData.weeks,
      hoursPerWeek: newData.hours,
      gradesInvolved: newData.grades,
      id: newData.id,
      participationLevelId: newData.accomplishment ? newData.accomplishment : '',
      dropdownId: newData.sport ? newData.sport : '',
    });
  }

  return updatedData;
};



// export const convertDateFormat =(dateStr)=> {
//   const months = {
//     Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
//     Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
//   };

//   const [monthStr, day, year] = dateStr.split(' ');
//   const month = months[monthStr];
//   const formattedDay = day.replace(',', '').padStart(2, '0'); 

//   console.log(month, 'monthmonth')
//   console.log(formattedDay, 'formattedDayformattedDay')
//   console.log(year, 'yearyearyear')


//   return `${year}-${month}-${formattedDay}`;
// }


export const fixScholarshipTitle = (title) => {
  if (title) {
    if (title.length > 20) {
      return `${title.slice(0, 20)}...`;
    } else {
      return `${title}...`;
    }
  }
};

export const fixLink = (title) => {
  if (title) {
    if (title.length > 80) {
      return `${title.slice(0, 80)}...`;
    } else {
      return title;
    }
  }
};



export const updateQuestions = (newData, answers, type) => {
  const updatedData = [...answers];

  const existingDataIndex = updatedData.findIndex((item) => item.id === newData.id);

  if (existingDataIndex !== -1) {
    if(type === 'universities'){

    updatedData[existingDataIndex] = {
      ...updatedData[existingDataIndex],
      title: newData.title ? newData.title : '',
      description: newData.description ? newData.description : '',
    };

  }else if(type === 'news'){
    updatedData[existingDataIndex] = {
      ...updatedData[existingDataIndex],
      title: newData.title ? newData.title : '',
      description: newData.description ? newData.description : '',
      link: newData.link ? newData.link : '',
    };
  }else if(type === 'scholarship'){
    updatedData[existingDataIndex] = {
      ...updatedData[existingDataIndex],
      award_worth: newData?.award_worth,
      category: newData?.category,
      deadline: newData?.deadline,
      description: newData?.description,
      grade: newData?.grade,
      grade_level: newData?.grade_level,
      link: newData?.link,
      offered_by: newData?.offered_by,
      title: newData?.title,
    };
  }
}
  return updatedData;
};


export const savedChange = (questions, newData, isSaved) => {
  const updateSavedQuestion = [...questions];
  const existingData = updateSavedQuestion.find((item) => item.id === newData.id);

  if (existingData) {
    existingData.saved = isSaved;
  }
  return updateSavedQuestion;
};